<script lang="ts" setup>
import { ref } from 'vue'

const menuOpen = ref<boolean>(false)

function menuOpenChanged(value: boolean) {
  menuOpen.value = value
  console.debug('Menu open changed', value)

  // Prevent scrolling when the menu is open
  document.querySelector('body')?.setAttribute('style', `overflow: ${value ? 'hidden' : 'auto'}`)
}
</script>

<template>
  <div id="main-container">
    <Header @menu-open="menuOpenChanged" />
    <main :aria-hidden="menuOpen" :class="{ 'menu-open': menuOpen }">
      <slot />
    </main>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styling/_functions';

#main-container {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;

  &.menu-open {
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1;
    }
  }
}
</style>
