<script lang="ts" setup>
import { ref } from '#imports'

const emit = defineEmits(['menuOpen'])
const menuButton = ref<HTMLButtonElement | null>(null)
const firstChild = ref<HTMLLIElement | null>(null)
const menuOpen = ref<boolean>(false)

function openMenu(): void {
  menuOpen.value = true
  firstChild.value?.focus()
  emit('menuOpen', true)
}

function closeMenu(): void {
  menuOpen.value = false
  menuButton.value?.focus()
  emit('menuOpen', false)
}
</script>

<template>
  <button
    id="menu-opener"
    ref="menuButton"
    :aria-hidden="menuOpen"
    aria-haspopup="dialog"
    class="content-end"
    type="button"
    @click="openMenu()"
  >
    <span aria-hidden="true">
      <IconsGroup1902 />
    </span>
  </button>
  <div
    id="slide-nav"
    :class="{ 'active visible': menuOpen }"
    aria-labelledby="menu-opener-title"
    aria-modal="true"
    role="dialog"
  >
    <h2 id="menu-opener-title" v-t="'menu.label'" class="sr-only"></h2>
    <button id="menu-closer" :aria-label="$t('menu.close')" type="button" @click="closeMenu()">
      >
    </button>
    <nav>
      <ul>
        <li ref="firstChild">
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/">Contact</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styling/_functions';

#menu-opener {
  background: transparent;
  border: none;
  padding: toRem(8);
  width: toRem(40);
  height: toRem(40);
  overflow: hidden;

  span {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    color: var(--primary-button-background-color);
  }
}

#menu-closer {
  background: transparent;
  border: none;
  padding: toRem(16);
  margin: 0;
  overflow: hidden;
  color: var(--primary-text-color);
  font-size: 2rem;
}

#slide-nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tertiary-background-color);
  border-left: 1px solid var(--quaternary-background-color);
  color: var(--primary-text-color);
  z-index: 1000;

  &.visible {
    display: block;
    width: toRem(300);
  }

  ul {
    li {
      font-size: toRem(18);
      padding: toRem(16);
      border-bottom: 1px solid var(--quaternary-background-color);
    }
  }
}
</style>
