<script setup lang="ts">
const year = new Date().getFullYear()
</script>

<template>
  <div id="footer-wrapper">
    <footer class="py-0 lg:container lg:mx-auto">
      <div id="footer-inner-wrapper" class="w-full px-4">
        <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
          <div class="logo col-span-2 mb-4 lg:col-span-1 lg:mb-0">
            <svg width="100%" viewBox="0 0 170.835 44.053" :aria-label="$t('footer.logo')">
              <use xlink:href="~/assets/icons/logo.svg#Group_1895"></use>
            </svg>
          </div>
          <nav class="links services" aria-labelledby="footer-links-services-title">
            <h2 id="footer-links-services-title" v-t="'footer.servicesLabel'"></h2>
            <!--Todo get from remote?-->
            <ul>
              <li>Bernmonumenten</li>
              <li>Oorlogsmonumenten</li>
              <li>Gemeentelijke monumenten</li>
              <li>Persoonlijke monumenten</li>
            </ul>
          </nav>
          <nav class="links content" aria-labelledby="footer-links-content-title">
            <h2 id="footer-links-content-title" v-t="'footer.contentLabel'"></h2>
            <!--Todo get from remote?-->
            <ul>
              <li>Gedichten</li>
              <li>Overlijdensberichten</li>
              <li>Over condoleren</li>
            </ul>
          </nav>
          <nav class="links contact" aria-labelledby="footer-links-contact-title">
            <h2 id="footer-links-contact-title" v-t="'footer.contactLabel'"></h2>
            <!--Todo get from remote?-->
            <ul>
              <li>Over ons</li>
              <li>Pers</li>
              <li>Contact</li>
              <li>Algemene voorwarden</li>
              <li>Veelgestelde vragen</li>
            </ul>
          </nav>
        </div>
        <div id="footer-closing-links">
          <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
            <span v-t="{ path: 'footer.copyright', args: { year: year } }"></span>
            <span v-t="'footer.privacyInformation'"></span>
            <span v-t="'footer.cookieStatement'"></span>
            <span v-t="'footer.privacyStatement'"></span>
          </div>
          <span v-t="'footer.slogan'"></span>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
@import '~/assets/styling/_functions';

#footer-wrapper {
  background-color: var(--tertiary-background-color);
  color: var(--quaternary-text-color);

  footer {
    padding: toRem(57) 0 toRem(19);

    #footer-inner-wrapper {
      svg {
        color: var(--tertiary-text-color);
        height: toRem(44);
      }

      nav {
        h2 {
          font-size: toRem(18);
          font-weight: var(--bold-weight);
          margin-bottom: toRem(16);
          color: var(--tertiary-text-color);
        }

        ul {
          margin-top: toRem(22);
          font-size: toRem(14);

          li {
            margin: toRem(16) 0;
          }
        }
      }

      #footer-closing-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        font-size: toRem(12);
        margin-top: toRem(32);

        span {
          margin: toRem(16);
        }

        @include lgScreen {
          margin-top: toRem(100);
          flex-direction: row;

          span {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
