<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 26 19.086">
    <g id="Group_1902" data-name="Group 1902" transform="translate(3682 19915.043)">
      <path
        id="Line_180"
        data-name="Line 180"
        d="M24,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H24a1,1,0,0,1,1,1A1,1,0,0,1,24,1Z"
        transform="translate(-3681 -19914.043)"
        fill="currentColor"
      />
      <path
        id="Line_181"
        data-name="Line 181"
        d="M24,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H24a1,1,0,0,1,1,1A1,1,0,0,1,24,1Z"
        transform="translate(-3681 -19905.5)"
        fill="currentColor"
      />
      <path
        id="Line_182"
        data-name="Line 182"
        d="M24,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H24a1,1,0,0,1,1,1A1,1,0,0,1,24,1Z"
        transform="translate(-3681 -19896.957)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
