<script lang="ts" setup>
const emit = defineEmits(['menuOpen'])

function menuOpenChanged(state: boolean) {
  emit('menuOpen', state)
}
</script>

<template>
  <div id="header-wrapper">
    <header class="py-0 lg:container lg:mx-auto">
      <div id="header-inner-wrapper" class="w-full columns-2 px-4">
        <svg :aria-label="$t('header.logo')" viewBox="0 0 170.835 44.053" width="100%">
          <use xlink:href="~/assets/icons/logo.svg#Group_1895"></use>
        </svg>
        <NavigationMenu @menu-open="menuOpenChanged" />
      </div>
    </header>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styling/_functions';

#header-wrapper {
  background-color: var(--secondary-background-color);

  header {
    height: toRem(80);
    z-index: 1;

    #header-inner-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    svg {
      height: toRem(33);
      width: auto;
      color: var(--primary-button-background-color);

      @include lgScreen {
        height: toRem(44);
      }
    }
  }
}
</style>
